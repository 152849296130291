<template>
  <div class="about">
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="~static/debris.png" />
    <img class="Aboutbg" style="top: 17%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 60%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 33%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 56%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="bottom: 0; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 34%; right: 0" src="~static/wasteR.png" />
    <img
      class="Aboutbg"
      style="bottom: 13%; right: 0"
      src="~static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 170px 0">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="hr-more">
                <div>条子泥観光地の紹介</div>
              </div>

              <div class="scenic">
                <div style="position: relative">
                  <span class="video-box"></span>
                  <video
                    style="object-fit: fill; margin-top: -81px"
                    width="550"
                    height="500"
                    poster="~static/videoFm.png"
                    controls="controls"
                    src="http://117.80.146.163:18080/tznmh/tzn_img/tiaozini.mp4"
                    type="video/mp4"
                  ></video>
                </div>
                <div
                  style="
                    position: relative;
                    z-index: 2;
                    width: 430px;
                    height: 400px;
                    overflow-y: auto;
                    padding-right: 16px;
                  "
                >
                  <!-- <img class="general01" src="~static/general01.png" />
                  <img class="general02" src="~static/general02.png" />
                  <img class="general05" src="~static/general05.png" /> -->
                  <!-- <p v-html="decodeHTML( tzn_gkText[0] && tzn_gkText[0].content)"></p> -->
                  <!-- <p style="text-align: left"> -->
                  <p>
                    東台条子泥湿地は黄海生態区南部の東台沿海地帯に位置し、総面積が600平方キロメートル、条子泥観光地が10.77平方キロメートルの面積を占めています。条子泥湿地は江蘇省最初、中国国内十四番目の世界自然遺産であり、さらに中国の臨海湿地類世界自然遺産の空白を埋めました。
                  </p>
                  <p>
                    条子泥湿地は海に向かって生まれ、鳥のおかげで有名になります。同湿地は暖温帯と亜熱帯の過渡的な地帯に位置し、気候が穏やかで温かくて、雨量も豊かで、シルト質の干潟が水分と塩分などの栄養素を固定でき、貝類が多く生息し、海沿いの芝生が大幅に広げて、固い岩石海岸より多種類の生息に向き、数多くの鳥によってこの湿地が活用されて移動中の一時休暇がされたり、換羽と越冬がされたりし、ついに世界的な九大の渡り鳥移動区の一つである東アジア―オーストラリア移動経路の中心点になります。170種程度の水鳥類を含む410種の各種類の鳥類、計100万羽ほどが観察記録され、なかに12種がIUCNレッドリストに登録された絶滅危惧種に属し、中国一級、二級保護鳥類にそれぞれ21種と71種が対象に指定され、同湿地が「鳥類の国際空港」と賞賛されます。
                  </p>
                  <p>
                    母親河--黄河、長江は東へと奔走し、大量の土砂を運んでここで合流、沈殿し、「輻射砂稜群」、「一線潮」、「二分水」、「潮汐の森」など中国沿海ならではの地形景観を形成し、また「日光を浴びる海」、「天空の鏡」、「純粋な星空」、「霞に水鳥類の群れ」、「中国紅」などの美しい自然景観も揃っています。
                  </p>
                  <p>
                    条子泥は「緑色」の湿地である一方、「赤色」の地でもあります。なぜなら、ここは中国人民解放軍海軍の出帆地だからです。新四軍蘇中軍区海防団はここから海に入り、「紅帆船」を活用して商品の販売の名義で真の目的をかくまって、実際は蘇北、魯南と上海の間に抗日軍需物資の海上輸送線を構築しました。『51兵站』という映画の中には、波に乗って勇ましく前進する船の原型がこの「紅帆船」です。その後、同海防団も有名な渡江戦役にも参加し、人民海軍の重要な一部となります。「紅帆船」は、天幕が「拷皮」という技術で赤く染められたから名付けられます。この「紅帆船」は南湖の上の「赤い船」と同じように、並ならぬ歴史的価値を持っていて、今は条子泥の港湾に静かに停泊されて人々の見学と追憶の対象になります。
                  </p>
                </div>
              </div>
            </div>
            <!-- <div
              class="generalImg"
              :style="{ 'background-image': 'url(' + backgroundImg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat' }"
            ></div>-->
          </div>
          <div
            id="introduce"
            class="introduce"
            style="padding: 120px 80px 0 80px"
          >
            <div class="hr-more">観光スポットの紹介</div>
            <div style="width: 77%; margin: 0 auto">
              <div class="introduce_tabs">
                <span
                  v-for="(item, index) in tabsList"
                  style="width: 16.67%"
                  :key="item.name"
                  @click="ApiintroduceList(String(index))"
                >
                  <img width="113px" :src="item.src" />
                  <p>{{ item.name }}</p>
                </span>
              </div>
              <div style="margin-top: 80px; padding-left: 100px; display: flex">
                <div
                  style="
                    width: 40%;
                    margin-right: 50px;
                    padding-top: 25px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <p class="introduceText" v-html="content"></p>
                </div>
                <div style="width: 880px; position: relative">
                  <img
                    style="position: absolute; z-index: 9"
                    src="~static/introducebg01.png"
                    alt=""
                  />
                  <el-carousel
                    height="500px"
                    style="width: 110%; margin-left: 60px; margin-top: 30px"
                  >
                    <el-carousel-item v-for="i in introduceList" :key="i.id">
                      <el-image
                        style="height: 600px"
                        :fit="'cover'"
                        :src="i.thumbnail"
                      ></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="hr-more">美しい写真</div>
            <div class="imgBox">
              <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                stretch
                style="width: 100%; padding: 0 30px"
              >
                <el-tab-pane
                  v-for="i in imageTitleList"
                  :key="i.id"
                  :label="i.catalogueName"
                  :name="i.id"
                >
                  <div
                    class="bird-image"
                    v-if="i.id == '402880447ba001ab017ba0096fdb005f'"
                  >
                    <div
                      class="brief"
                      @click="handleGo(item.birdType, item.catalogueId)"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <!-- <p>
                          {{ item.birdType && item.birdType.split("|")[3] }}
                        </p> -->
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="scenic-cultural" v-else>
                    <div
                      class="tzn-brief"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="greatDeeds">
            <div style="width: 60%; margin: 0 auto; position: relative">
              <div class="hr-more">ナイスビデオ</div>
              <div class="movie">
                <div
                  class="video"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                  <span class="video-box2"></span>
                  <video
                    style="object-fit: fill"
                    controls="controls"
                    :src="item.videoPath"
                    type="video/mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more">イベント回顧</div>
            <div>
              <div class="Hrbg2" style="font-size: 16px">
                条子泥にフォーカス・写真祭り
              </div>
              <div class="tabs">
                <div
                  @click="tabsListApi(2, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? 'tabsB' : ''"
                >
                  おーねん さくひん
                </div>
                <div
                  @click="tabsListApi(1, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? '' : 'tabsB'"
                >
                  げんばしゅざい
                </div>
              </div>
              <div class="imgBox">
                <div
                  v-for="item in tabs01List"
                  :key="item.id"
                  :style="{
                    'background-image': 'url(' + item.picUrl + ')',
                    margin: '40px',
                    'background-repeat': 'no-repeat',
                    'background-position': '50%',
                    'border-radius': '50%',
                    'background-size': 'cover',
                  }"
                >
                  <img
                    style="margin-left: -40px"
                    src="~static/tabsbg01.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">干潟遊び</div>
            <div class="imgBox">
              <div
                v-for="item in tabs02List"
                :key="item.id"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '20px',
                  'background-repeat': 'no-repeat',
                  width: '370px',
                  height: '244px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -10px"
                  src="~static/tabsbg02.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">トライアスロン</div>
            <div class="imgBox">
              <div
                v-for="item in tabs04List"
                :key="item.img"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '50px',
                  'background-repeat': 'no-repeat',
                  width: '348px',
                  height: '348px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -4px"
                  src="~static/tabsbg04.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            id="geography"
            class="geography"
            style="padding: 120px 80px 80px 80px"
          >
            <div class="hr-more">観光地までの交通アクセス</div>
            <div
              style="height: 650px; display: flex; margin: 0 auto; width: 90%"
            >
              <div class="greatDeeds_2">
                <p style="font-weight: bold">
                  ドライブ旅行: <br /><br />
                  ルート1: <br />
                  →G15瀋海高速道路 <br />
                  →S352省道に沿って東へ42㎞を走行<br />
                  →条子泥観光地に到着 <br /><br />
                  ルート2: <br />
                  →G228 <br />
                  →左折してS352省道に入ってから500mを走行 <br />
                  →条子泥観光地に到着 <br /><br />
                  バス：<br />
                  東台バス停--条子泥<br />
                  6:10、6:40、7:40、11:40、14:40<br />
                  条子泥--東台バス停<br />
                  8:40、9:40、10:10、10:40、<br />
                  11:40、12:10、13:10、14:10、<br />
                  14:40、17:50、<br />
                  三倉烈士の霊園- -条子泥<br />
                  8:00、9:00、12:30、13:30、14:30、15:30<br />
                  条子泥--三倉烈士の霊園<br />
                  9:50、10:50、13:30、14:30、14:50、16:50<br />
                  お問い合わせ：0515-85680999<br />
                  救急電話：0515-85680996<br />
                  苦情受付：0515-85680998<br />
                </p>
              </div>
              <iframe
                v-if="flag"
                style="width: 100%; height: 100%; margin-left: 20px"
                name="my-iframe"
                id="my-iframe"
                src="https://apis.map.qq.com/uri/v1/marker?marker=coord:32.760766,120.908494;title:条子泥游客中心;&referer=myapp"
                frameborder="0"
                width="100vw"
                height="100vh"
                scrolling="no"
              ></iframe>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more">
              <div>景勝ち地の入場けん券</div>
            </div>
            <div>
              <div class="essentialImg">
                <img style="width: 65%" src="~static/JPessential1.png" alt="" />
              </div>
            </div>
            <div class="essentialImg">
              <img
                style="vertical-align: middle; width: 100%"
                src="~static/JPessential4.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="Lfloat" ref="Lfloat">
          <div>
            <!-- <p>走进条子泥</p> -->
            <!-- <a href="javascript:;" @click="backTop">条子泥印象</a> -->
            <router-link to="/Japanese?name=Introduction"
              >観光地の紹介</router-link
            >
            <router-link to="/Japanese?name=ScenicSpot"
              >観光スポット</router-link
            >
            <router-link to="/Japanese?name=Pictures">美しい写真</router-link>
            <router-link to="/Japanese?name=Videos">ナイスビデオ</router-link>
            <router-link to="/Japanese?name=Review">イベント回顧</router-link>
            <router-link to="/Japanese?name=Traffic">こうつう</router-link>
            <router-link to="/Japanese?name=TicketandService"
              >入場けん券</router-link
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import { tzn_tplbAPI, tzn_tpxqAPI, tzn_jmspAPI } from "@/api/test/scenery";
export default {
  name: "About",
  data() {
    return {
      list: ``,
      slideshowList: ``,
      // backgroundImg: ``,
      introduceList: {},
      content:
        "条子泥における高潮位での生息地は、総面積が720ムー、ここで水面、陸上、空中の三つの面から720度のパノラマの海鳥観覧ができるので、週間的に「720」と呼ばれます。2020年4月、渡り鳥が満潮を避けるための憩いの場を提供するために作られました場所なのです。この場所で生息する鳥類は何万羽がいって計410種に達し、国際鳥類保護団体に「渡り鳥保護における模範的かつ典型的な一例」と賞賛されています。",
      tabsList: [
        { name: "7 2 0", src: require("static/tabs01.png") },
        { name: "二分水", src: require("static/tabs02.png") },
        { name: "一線潮", src: require("static/tabs03.png") },
        { name: "湿地の中国紅", src: require("static/tabs04.png") },
        { name: "天の鏡", src: require("static/tabs05.png") },
        { name: "潮汐の森", src: require("static/tabs06.png") },
      ],
      tabs01List: [],
      tabs02List: [],
      tabs04List: [],
      activeNum: 4,
      activeNum2: true,
      tzn_gkText: ``,
      tzn_jtznText: ``,
      greatDeeds_list: ``,
      activeName: "402880447ba001ab017ba0096fdb005f",
      imageTitleList: [
        { id: "402880447ba001ab017ba0094ba9005b", catalogueName: "けしき" },
        { id: "402880447ba001ab017ba0096fdb005f", catalogueName: "鳥類" },
        { id: "402880447ba001ab017ba009aa340063", catalogueName: "かるちゃ" },
      ],
      pictureList: [],
      videoList: [],
      flag: false,
    };
  },
  methods: {
    ApiintroduceList(key) {
      switch (key) {
        case "0":
          api.tzn_sdjs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "条子泥における高潮位での生息地は、総面積が720ムー、ここで水面、陸上、空中の三つの面から720度のパノラマの海鳥観覧ができるので、週間的に「720」と呼ばれます。2020年4月、渡り鳥が満潮を避けるための憩いの場を提供するために作られました場所なのです。この場所で生息する鳥類は何万羽がいって計410種に達し、国際鳥類保護団体に「渡り鳥保護における模範的かつ典型的な一例」と賞賛されています。";
          break;
        case "1":
          api.tzn_efs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "条子泥観潮スポットでは「二分水」（清濁明らかな海水）という不思議な景観も見学できます。東台海域において数十カ所の大きさも向きもそれぞれの可変性の砂洲が点在しています。その中、東沙砂洲と西沙砂洲の間に、南北方向に走っている西洋と呼ばれる海域が存在しています。西洋はラッパの形をしていて、毎日潮が満ちるとき、黄海の西部海域から大きな勢いで流れてきた潮波が西洋の北部に入り、一方、東海の北部から滔々と流れてきた潮波が西洋の南部にぶつかり、そして、二つの潮波がここでぶつかり合い、非常に壮大な「二分水」の奇観が生まれます。条子泥では一年中のどの季節においても「二分水」の奇観を観覧できます。7、8、9月の集中的な増水期において、もし風力7以上の北東風が吹くと、潮が満ちるとき、南北の二つの方向からの力が伯仲している潮波が西洋のU文字形の狭い廊下状ルートに入り込んだら、速やかに高さ1ｍから2ｍに達する「虎頭潮」が形成されます。先の潮頭はまだ退かず、後の潮頭が追ってついて、追いつ追われつする潮がぶつかり合うと観音合掌のように、砕き散れると天女散花のように見えます。この壮大な場面は10分間か20分間持続できます。面白いことに、西洋の海面でぶつかり合う南と北からの二つ潮は引く際に、相変わらずそれぞれ流れてくるルートに沿って遠く天の果てにある海に戻っていきます。";
          break;
        case "2":
          api.tzn_sdtm().then((res) => {
            this.introduceList = res;
            this.content =
              "毎年の秋分の時期に、条子泥での上げ潮の景色が一番壮大になり、海上で風力が強くなると「一線潮」の景色も見られます。「一線潮」とは、干潟の果てから一線の白い糸が徐々に現れ、ゴロゴロした大きな音も聞こえてくる景色をいいます。音がだんだん大きくなり、遠い海面から一線の白い糸が現われて速やかに前に向かって移動し、まるで「素練横江、漫々平沙起白虹」という詩文の描写のようです。潮が一枚の白い壁のように迅速に進んで、目の前にやってきて、千軍万馬の勢いがあります。 ";
          });
          break;
        case "3":
          api.tzn_gcth().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "サバクヨモギは塩蒿草とも呼ばれ、一年生の草木であり、海辺、荒地、水路岸、田辺などの塩分とアルカリを含む土壌によく生育し、ズグロカモメに対して最も理想的な繁殖地帯になります。マツナの生育地は条子泥観光地の海景通りの北のほうに位置し、約5,000ムーがあり、秋になると浜辺全体が赤く染められ、「湿地の赤い絨毯」と賞賛されます。ネット人気者が殺到した新たな目的地として、ここは何度も新華網、強国網などのインターネットプラットフォームに報道されたことがあります。";
          break;
        case "4":
          api.tzn_hqxq().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "潮はまだ満たされておらず、海は滑らかな鏡のように、天と地を映し出し、雲の海にあなたを置きます。 ペアは二重に、ミラーの世界を構成する。";
          break;
        case "5":
          api.tzn_sdsj().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "潮が落ちたら砂州で絵のような跡が残されて、潮の干満によってできた溝は形が天まで届く大木のようで、条子泥湿地でよく見えるため、「潮汐の森」と呼ばれています。青い空、美しい湿地、万鳥翔集、調和共生。";
          break;
        default:
          break;
      }
    },
    //鸟类图片跳转
    handleGo(birdType, catalogueId) {
      this.$router.push({
        path: "/scenerydetails",
        query: {
          birdType: birdType.split("|")[2],
          catalogueId,
        },
      });
    },
    //teb切换
    handleClick(tab, event) {
      console.log(tab, event);
      tzn_tpxqAPI({
        isUse: 1,
        catalogueId: this.activeName,
        size: this.activeName == "402880447ba001ab017ba0096fdb005f" ? 8 : 6,
        page: 0,
      }).then(({ data }) => {
        this.pictureList = data;
      });
    },
    tabsListApi(e = 1, activeId) {
      api
        .layUIPage({
          isUse: "1",
          activeId,
          picType: e,
          size: 6,
          page: 0,
        })
        .then(({ data }) => {
          switch (activeId) {
            case "402880447baab07f017baac4d5fc00bb": // 摄影欢乐会
              this.tabs01List = data;
              e == 1 ? (this.activeNum2 = true) : (this.activeNum2 = false);
              break;
            case "402880447baab07f017baac511bd00c0": //滩玩儿
              this.tabs02List = data;
              break;
            case "402880447baab07f017baac5d7dc00cf": // 铁人三项
              this.tabs04List = data;
              break;
            default:
              break;
          }
        });
    },
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 430) {
        this.$refs.Lfloat.style.top = 617 - scrollTop + "px";
      } else {
        this.$refs.Lfloat.style.top = "30%";
      }
      if (scrollTop > 7700) {
        this.flag = true;
      }
      console.log(scrollTop, "位置");
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {},
  mounted() {
    //图片目录
    // this.$nextTick(() =>{
    // tzn_tplbAPI().then((res) => {
    //   this.imageTitleList = res;
    // });
    // })

    //精美视频
    tzn_jmspAPI({
      isUse: 1,
      size: 6,
      page: 0,
    }).then((res) => {
      this.videoList = res.data;
    });
    this.handleClick(); //精美图片
    api.tzn_sdjs().then((res) => {
      this.introduceList = res;
    });
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    api.tzn_gk().then(({ data }) => {
      this.tzn_gkText = data;
    });
    api.tzn_jtzn().then(({ data }) => {
      this.tzn_jtznText = data;
    });
    api.tzn_zdsj().then(({ data }) => {
      this.greatDeeds_list = data;
    });
    this.tabsListApi(2, "402880447baab07f017baac4d5fc00bb"); // 摄影欢乐会
    this.tabsListApi(0, "402880447baab07f017baac511bd00c0"); //滩玩儿
    this.tabsListApi(0, "402880447baab07f017baac5d7dc00cf"); // 铁人三项
    window.addEventListener("scroll", this.windowScroll);

    // console.log(this.introduceList);
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style scoped>
.essentialImg {
  text-align: center;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 20px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  width: 440px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  margin: 0 auto;
  font-size: 28px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #f6d397;
  background: url("~static/Hrbg2.png") no-repeat;
}
.movie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.image {
  width: 450px;
  height: 250px;
  background-image: url("../../../static/scenery/img-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
.video {
  position: relative;
  margin-bottom: 30px;
  width: 31%;
  height: 355px;
}
.video-box2 {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url("../../../static/scenery/video-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -2px;
}
.video > video {
  width: 98%;
  height: 100%;
}
/deep/ .el-tabs__item {
  background-image: url("~static/scenery/bgbox.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #f6d397;
  margin: 0 15px;
  padding: 0;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
/deep/ .el-tabs__item.is-active {
  background-image: url("~static/scenery/bgbox-select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #509ab1;
}
/deep/ .el-tabs__active-bar {
  display: none;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__nav.is-stretch {
  min-width: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  margin-bottom: 52px;
}
.brief {
  margin: 8px;
  width: 23%;
  cursor: pointer;
}
.tzn-brief {
  margin: 8px;
  width: 30%;
  cursor: pointer;
}
.bird-box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 98% 98%;
}
.bird-box > p {
  position: absolute;
  top: 21%;
  left: 10%;
  width: 30px;
  font-size: 28px;
  font-family: "tzn-font";
  color: #fff;
  line-height: 40px;
}
.bird-box > img {
  display: block;
  width: 100%;
  height: auto;
}
.bird-image,
.scenic-cultural {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.video-box {
  width: 783px;
  height: 700px;
  background-image: url(~static/videoBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -116px;
  top: -29%;
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
>>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #509ab1 !important;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs,
.scenic {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.introduce_tabs:before,
.introduce_tabs:after,
.scenic:before,
.scenic:after {
  content: "";
  display: block;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
  white-space: nowrap;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  /* position: absolute; */
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.greatDeeds_bzt {
  height: 100%;
  width: 2px;
  margin: 0 auto;
  border: none;
  background: darkgray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.greatDeeds_bzt > div {
  height: 136px;
  margin: 40px 0;
}
.greatDeeds_bzt > div > h2 {
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.generalbg {
  left: -45%;
  top: -27%;
}
.general01 {
  right: -27%;
}
.general02 {
  right: -36%;
  top: 65%;
}
.general03 {
  height: 140px;
  left: -18%;
  top: -6%;
}
.general04 {
  bottom: -25%;
  left: 88%;
  z-index: -1;
}
.general05 {
  left: -30%;
}
.general06 {
  writing-mode: tb-rl;
  position: absolute;
  right: -51%;
  top: 4%;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #363a31;
  line-height: 24px;
}
.greatDeeds {
  /* background: url("~static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
  height: 1000px; */
  /* transform: scale(0.93, 1); */
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("~static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("~static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}
.Lfloat {
  /* display: none; */
  position: fixed;
  width: 352px;
  height: 527px;
  left: -2%;
  top: 617px;
  z-index: 99;
  background: url("~static/flotbg2.png") no-repeat 100% 100%;
  /* transform: scale(0.75); */
}
.Lfloat > div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 38%;
}
.Lfloat > div > p {
  text-align: center;
  height: 23px;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #5098af;
  line-height: 11px;
}
ul {
  list-style: none;
}
.Lfloat a {
  display: block;
  color: #5098af;
  line-height: 27px;
  margin: 13px 0px -3px 0px;
  text-align: center;
  width: 128px;
  height: 29px;
  background: url("~static/flotInbg.png") no-repeat 100% 100%;
}
.introduceText {
  white-space: pre-wrap;
  overflow-y: auto;
  margin-top: 10px;
}
.introduce {
  height: 1100px;
  /* background: url("~static/introducebg.png") no-repeat; */
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  margin-left: 10%;
  /* width: 45%; */
  height: 100%;
  overflow-y: auto;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.icon1 {
  background: url("~static/msg.png") no-repeat;
}
.icon2 {
  background: url("~static/site.png") no-repeat;
}
.icon3 {
  background: url("~static/time.png") no-repeat;
}
.icon4 {
  background: url("~static/icon4.png") no-repeat;
}

>>> .el-tabs__content {
  /* height: 300px; */
}
.hr-more {
  white-space: nowrap;
  /* letter-spacing: 3px; */
  line-height: 250px;
  z-index: 9;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -19px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("~static/Hrbg.png");
  font-size: 21px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.about {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("~static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  text-indent: 28px;
  text-align-last: start;
  line-height: 2.5;
  /* writing-mode: tb-rl; */
  /* letter-spacing: 12px; */
}
.generalImg {
  flex: 1;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(0.8);
  width: 1400px;
  margin: 0 auto;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>
